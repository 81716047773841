
.Cell {
    display: table-cell;
    border-style: solid;
    max-width: 30px;
}

.Title {
    margin: 5px;
    color: white;

}

.Table {
    display: table;
    width: 100%;
    text-align: center;
    min-height: 70vh;
    border-style: solid;
    
}

.Block {
    padding: 0px;
    margin: 5px;
    border: 2px;
    border-color: rgb(207, 203, 203);
    border-style: solid;
    text-align: center;
   font-size: 16px;
   text-decoration: none;
   border-radius: 5px;
}

.Block a {
    text-decoration: none;
    color: black;
}

.Block:hover {
    background-color: rgb(179, 175, 175);
}

.Brick{
   display: inline-block;
   width: 100%;
   height: 100%;
   padding-bottom: 20px;
   padding-top: 20px;
}