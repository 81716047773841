.boldheader {
    border-bottom: 5px solid white;
    border-top: 5px solid white;
    font-size: 30px;
    display: flex; 
    justify-content: center;
    align-items: center;
    margin: 0 50px 15px 50px;
    color: white;
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 0 10px 0;
  }

  .banner2 {
    background: radial-gradient(circle, rgba(2, 0, 36, 0.9026961126247374) 8%, rgba(0, 212, 255, 1) 51%, rgba(23, 23, 96, 1) 90%);
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
    min-height: 100vh;
  }

  .border{
    display: 'flex'; 
    justify-content: 'center';
    border-width: 15px;
    border-style: solid;
  }

  .banner3 {
    min-height: 100vh;
    background: black;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
  }

 