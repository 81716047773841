
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&family=Kanit:ital,wght@1,600&display=swap');

.Header {
    padding: 2px 0 2px 0; 
    display: flex;
    align-items: center; 
    justify-content: center;
    color: white; 
    flex-direction: column;
    background-image: url(../../images/Banner13Img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    
    /* background: radial-gradient(circle, rgba(2, 0, 36, 0.9026961126247374) 8%, rgba(0, 212, 255, 1) 51%, rgba(23, 23, 96, 1) 90%); */
   
}

.Header > nav > ul {
    display: flex; 
    list-style: none; 
    font-size: 15px; 
    flex-direction: row;
    margin-right: 30px;
    font-weight: bold;
    margin: 0px 0 0 0;
   
}

.Header > nav > ul li {
    margin: 10px 20px 0px 20px;
    font-size: 25px; 
    padding-right: 0px;
 
}

.Header a {
    text-decoration: none;
    color: white;
  
}

.Header h1 {
    font-size: 40px;
    color: white;
    font-family: 'Kanit', sans-serif;
    /* font-family: 'EB Garamond', serif; */
    /* text-decoration: underline; */
    text-shadow: 0 0 0.05em rgb(255, 255, 255), 0 0 0.5em rgb(0, 6, 39);
    margin: 0px;
    padding: 25px 0 0 0;
    
}

.Header button {
    font-weight: bold;
    margin: 3px 0 0 0;
    color: white;
    font-size: 25px;
    text-decoration: none;
}

.logo {
    background-color: none;
    
}

.Title {
    margin-top: 40px;
   
}