.boldheader {

    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

.filter {
    display: flex;
    flex-direction: row;
    padding: 0 100%; 
    margin: 2px;
    border: 2px solid black;
  }

div > p {
    margin: 10px;

  }

  
.Cell {
  display: table-cell;
  border-style: solid;
  max-width: 30px;
}

.Title {
  margin: 5px;
  color: white;
  font-size: 18px;
}

.Table {
  display: table;
  width: 100%;
  text-align: center;
  min-height: 40vh;
  border-style: solid;
  
}

.Block {
  padding: 0px;
  margin: 5px;
  border: 2px;
  border-color: rgb(207, 203, 203);
  border-style: solid;
  text-align: center;
 font-size: 16px;
 text-decoration: none;
 border-radius: 5px;
}

.Block a {
  text-decoration: none;
  color: black;
}

.Block:hover {
  background-color: rgb(179, 175, 175);
}

.Brick{
 display: inline-block;
 width: 100%;
 height: 100%;
 padding-bottom: 20px;
 padding-top: 20px;
}

.TopBlock {
  padding: 0px;
  margin: 5px;

  text-align: center;
 font-size: 16px;
 text-decoration: none;
 border-radius: 5px;
}

.TopTitle {
  margin: 5px;
  color: black;

}

.Header {
  text-align: center;
  color: white;
  background-color: #051524ff;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 2px;
  border-color: black;
  border-style: solid;
}